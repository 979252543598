import { ApiClient } from "@/services/api/common.api";
import {
  CreateInternalCustomerOrderFromPredictionsCommand,
  CreateOrUpdateCustomerOrderCommand,
  CreateOrUpdateCustomerOrderLineCommand,
  CustomerOrderDto,
  CustomerOrderLineDetailsDto,
  CustomerOrderLineDto,
  GetCustomerOrderLinesDetailsPaginatedQuery,
  GetCustomerOrderLinesPaginatedQuery,
  GetCustomerOrdersPaginatedQuery,
  IAgGridPaginationResponse,
  RegisterCorrectCustomerOrderLineCommand,
  RegisterReleaseCustomerOrderLinesToProductionCommand,
  RegisterReleaseCustomerOrdersToProductionCommand, RegisterReReleaseCustomerOrderLinesCommand,
  RegisterUnreleaseCustomerOrderCommand,
  UpdateCustomerOrderModelInstanceAssignmentCommand
} from "@masta/generated-model";

export default {
  async getAll(scenarioId?: string) {
    return ApiClient.get<IAgGridPaginationResponse<CustomerOrderDto>>(`/customerOrders?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetCustomerOrdersPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<CustomerOrderDto>>(`/customerOrders`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<CustomerOrderDto>(`/customerOrders/${id}?scenarioId=${scenarioId}`);
  },
  async createOrUpdate(request: CreateOrUpdateCustomerOrderCommand) {
    return ApiClient.put<string>(`/customerOrders`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/customerOrders/${id}?scenarioId=${scenarioId}`);
  },
  async releaseToProduction(request: RegisterReleaseCustomerOrdersToProductionCommand) {
    return ApiClient.post("/customerOrders/releaseToProduction", request);
  },
  async getCustomerOrderLinesPaginated(request: GetCustomerOrderLinesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<CustomerOrderLineDto>>(`/customerOrders/${request.orderId}/lines`, request);
  },
  async getCustomerOrderLine(orderId: string, orderLineId: string, scenarioId: string) {
    return ApiClient.get(`/customerOrders/${orderId}/lines/${orderLineId}?scenarioId=${scenarioId}`);
  },
  async getCustomerOrderLineTaskIds(orderId: string, orderLineId: string, scenarioId: string) {
    return ApiClient.get<string[]>(`/customerOrders/${orderId}/lines/${orderLineId}/tasks?scenarioId=${scenarioId}`);
  },
  async releaseCustomerOrderLinesToProduction(request: RegisterReleaseCustomerOrderLinesToProductionCommand) {
    return ApiClient.post(`/customerOrders/${request.orderId}/releaseToProduction`, request);
  },
  async unrelease(request: RegisterUnreleaseCustomerOrderCommand) {
    return ApiClient.post(`/customerOrders/${request.orderId}/unrelease`, request);
  },
  async rerelease(request: RegisterReReleaseCustomerOrderLinesCommand) {
    return ApiClient.post(`/customerOrders/${request.orderId}/rerelease`, request);
  },
  async confirm(orderId: string) {
    return ApiClient.post(`/customerOrders/${orderId}/confirm`);
  },
  async markAsQuotationSent(orderId: string) {
    return ApiClient.post(`/customerOrders/${orderId}/markAsQuotationSent`);
  },
  async createOrUpdateCustomerOrderLine(request: CreateOrUpdateCustomerOrderLineCommand) {
    return ApiClient.put<string>(`/customerOrders/${request.orderId}/lines`, request);
  },
  async removeCustomerOrderLine(orderId: string, id: string, scenarioId?: string) {
    return ApiClient.delete(`/customerOrders/${orderId}/lines/${id}?scenarioId=${scenarioId}`);
  },
  async correctCustomerOrderLine(request: RegisterCorrectCustomerOrderLineCommand) {
    return ApiClient.post(`/customerOrders/${request.orderId}/lines/${request.id}/correct`, request);
  },
  async updateModelInstanceAssignment(request: UpdateCustomerOrderModelInstanceAssignmentCommand) {
    return ApiClient.post(`/customerOrders/updateModelAssignment`, request);
  },
  getOrderLineDetailsPaginated(request: GetCustomerOrderLinesDetailsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<CustomerOrderLineDetailsDto>>(`/customerOrderLines`, request);
  },
  async createFromInternalCustomerOrderPredictions(request: CreateInternalCustomerOrderFromPredictionsCommand) {
    return ApiClient.post(`/customerOrders/createFromPredictions`, request);
  }
};
