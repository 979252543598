import { AgChartsCommunityModule } from "ag-charts-community";
import {
  AllEnterpriseModule,
  LicenseManager,
  ModuleRegistry,
  ValidationModule
} from "ag-grid-enterprise";

// Create an array of modules to register
const modulesToRegister = [
  AllEnterpriseModule.with(AgChartsCommunityModule)
];

// Conditionally include ValidationModule if in development mode
if (import.meta.env.MODE === "development") {
  modulesToRegister.push(ValidationModule);
}

// Register the modules
ModuleRegistry.registerModules(modulesToRegister);

LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  "Using_this_{AG_Grid}_Enterprise_key_{AG-058150}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{MASTA}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MEMOM}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{MEMOM}_need_to_be_licensed___{MEMOM}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_June_2025}____[v3]_[01]_MTc0OTg1NTYwMDAwMA==94b19664a9723479d03d5cee31669f8c"
);
