import { App, getCurrentInstance } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import { asJiraFormattedString, serverSideValidationPattern } from "./durationTypeUtilities";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const $dateSymbol = Symbol("$date");
export const $dateFormatterSymbol = Symbol("$dateFormatter");
export const $dateTimeFormatterSymbol = Symbol("$dateTimeFormatter");
export const $dateTimeSecondFormatterSymbol = Symbol("$dateTimeSecondFormatter");
export const $dateTimeUrlSafeFormatterSymbol = Symbol("$dateTimeUrlSafeFormatter");
export const $timeFormatterSymbol = Symbol("$timeFormatter");
export const $durationFormatterSymbol = Symbol("$durationFormatter");
export const $longTermSecondsBasedDurationFormatterSymbol = Symbol("$longTermSecondsBasedDurationFormatter");

export type DateFormatter = (date: any, timeZone?: string) => string;
export type DurationFormatter = ((dateFrom: string, dateTo: string) => string) | DateFormatter;
export type SecondsDurationFormatter = (durationSeconds: number) => string;

export function useDateFormatters(): {
  $date: typeof dayjs;
  $dateFormatter: DateFormatter;
  $dateTimeFormatter: DateFormatter;
  $dateTimeSecondFormatter: DateFormatter;
  $dateTimeUrlSafeFormatter: DateFormatter;
  $timeFormatter: DateFormatter;
  $durationFormatter: DurationFormatter;
  $longTermSecondsBasedDurationFormatter: SecondsDurationFormatter;
} {
  const app = getCurrentInstance();
  return {
    $date: dayjs,
    $dateFormatter: app?.appContext.config.globalProperties.$dateFormatter,
    $dateTimeFormatter: app?.appContext.config.globalProperties.$dateTimeFormatter,
    $dateTimeSecondFormatter: app?.appContext.config.globalProperties.$dateTimeSecondFormatter,
    $dateTimeUrlSafeFormatter: app?.appContext.config.globalProperties.$dateTimeUrlSafeFormatter,
    $timeFormatter: app?.appContext.config.globalProperties.$timeFormatter,
    $durationFormatter: app?.appContext.config.globalProperties.$durationFormatter,
    $longTermSecondsBasedDurationFormatter: app?.appContext.config.globalProperties.$longTermSecondsBasedDurationFormatter
  };
}

function isString(s: any) {
  // Because in JavaScript strings can be literals or objects.
  return typeof s === "string" || s instanceof String;
}

function getJsDurationString(duration: string) {
  const s = duration.split("S");
  if (s.length === 0) return duration;
  if (s.length === 1) return s[0];
  return `${s[0]}S`;
}

export const DayjsPlugin = {
  install(app: App) {
    function preformatDuration(duration: string) {
      if (duration) {
        // duration may contain sub-seconds parts (millis, nanos), dayjs has problem to handle it correctly, so they need to be removed
        return duration.replace(/d+s/g, "").replace(/d+n/g, "");
      }
      return duration;
    }

    app.config.globalProperties.$date = dayjs;
    app.provide($dateSymbol, app.config.globalProperties.$date);

    app.config.globalProperties.$dateFormatter = (date: any, timeZone?: string) => {
      if (!date) return null;
      let d = dayjs(date);
      if (timeZone) {
        d = d.tz(timeZone);
      }
      return d.format("DD/MM/YYYY");
    };
    app.provide($dateFormatterSymbol, app.config.globalProperties.$dateFormatter);

    app.config.globalProperties.$dateTimeFormatter = (date: any, timeZone?: string) => {
      if (!date) return null;
      if (isString(date) && date.startsWith("-9998")) return "";
      if (isString(date) && date.startsWith("9999")) return "";

      let d = dayjs(date);
      if (timeZone) {
        d = d.tz(timeZone);
      }
      return d.format("DD/MM/YYYY HH:mm");
    };
    app.provide($dateTimeFormatterSymbol, app.config.globalProperties.$dateTimeFormatter);

    app.config.globalProperties.$dateTimeSecondFormatter = (date: any, timeZone?: string) => {
      if (!date) return null;
      let d = dayjs(date);
      if (timeZone) {
        d = d.tz(timeZone);
      }
      return d.format("DD/MM/YYYY HH:mm:ss");
    };
    app.provide($dateTimeSecondFormatterSymbol, app.config.globalProperties.$dateTimeSecondFormatter);

    app.config.globalProperties.$dateTimeUrlSafeFormatter = (date: any, timeZone?: string) => {
      if (!date) return null;
      let d = dayjs(date);
      if (timeZone) {
        d = d.tz(timeZone);
      }
      return d.format("YYYY-MM-DD-HH-mm-ss");
    };
    app.provide($dateTimeUrlSafeFormatterSymbol, app.config.globalProperties.$dateTimeUrlSafeFormatter);

    app.config.globalProperties.$timeFormatter = (date: any, timeZone?: string) => {
      if (!date) return null;
      let d = dayjs(date);
      if (timeZone) {
        d = d.tz(timeZone);
      }
      return d.format("HH:mm");
    };
    app.provide($timeFormatterSymbol, app.config.globalProperties.$timeFormatter);

    app.config.globalProperties.$durationFormatter = (dateFrom: string, dateTo: string) => {
      const format = "D[d] H[h] m[m] s[s]";

      if (serverSideValidationPattern.test(dateFrom) && !dateTo) {
        return asJiraFormattedString(dateFrom);
      }

      dateFrom = preformatDuration(dateFrom);
      dateTo = preformatDuration(dateTo);

      if (dateFrom && !dateTo) {
        dateFrom = getJsDurationString(dateFrom);
        const _duration = dayjs.duration(dateFrom.toUpperCase());
        return _duration ? _duration.format(format) : null;
      } else if (dateFrom && dateTo) {
        dateFrom = getJsDurationString(dateFrom);
        dateTo = getJsDurationString(dateTo);
        const _diff = dayjs(dateTo).diff(dayjs(dateFrom));
        const _duration = dayjs.duration(_diff);
        return _duration ? _duration.format(format) : null;
      } else {
        return null;
      }
    };
    app.provide($durationFormatterSymbol, app.config.globalProperties.$durationFormatter);

    app.config.globalProperties.$longTermSecondsBasedDurationFormatter = (durationSeconds: number) => {
      const _duration = dayjs.duration(durationSeconds, "seconds");

      const format = "D[d] H[h] m[m]";
      const formatWithMonths = "M[M] D[d] H[h]";
      const formatWithYears = "Y[Y] M[M] D[d]";

      return _duration
        ? _duration.asYears() >= 1
          ? _duration.format(formatWithYears)
          : _duration.asMonths() >= 1
            ? _duration.format(formatWithMonths)
            : _duration.format(format)
        : null;
    };
    app.provide(
      $longTermSecondsBasedDurationFormatterSymbol,
      app.config.globalProperties.$longTermSecondsBasedDurationFormatter
    );
  }
};
