<script lang="ts" setup>
defineProps<{
  id: string;
  message: string;
}>();
const emit = defineEmits(["on-click"]);
</script>

<template>
  <div class="notification" @click="emit('on-click', id)" v-html="message"></div>
</template>

<style lang="scss" scoped>
.notification {
  flex: 1;
  line-height: 24px;
  font-size: 16px;
  word-break: break-word;
  white-space: pre-wrap;
}
</style>
