import { ApiClient } from "@/services/api/common.api";
import { CreateMaterialMovementCommand, CreateMaterialMovementResponse, GetMaterialStockQuery, GetMaterialStockQueryResponse } from "@masta/generated-model";

export default {
  async getMaterialStock(getMaterialStockQuery: GetMaterialStockQuery) {
    return ApiClient.post<GetMaterialStockQueryResponse>(`/materialstock/stocks`, getMaterialStockQuery);
  },
  async createMaterialMovement(createMaterialMovementCommand: CreateMaterialMovementCommand) {
    return ApiClient.post<CreateMaterialMovementResponse>(`/materialstock/material-movement`, createMaterialMovementCommand);
  }
};
