import { ApiClient } from "@/services/api/common.api";
import {
  ChangeoverTransitionDto,
  CreateChangeoverTransitionCommand,
  GetChangeoverTransitionsPaginatedQuery,
  IAgGridPaginationResponse,
  UpdateChangeoverTransitionCommand
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetChangeoverTransitionsPaginatedQuery) {
    return await ApiClient.post<IAgGridPaginationResponse<ChangeoverTransitionDto>>(`/ChangeoverTransitions`, request);
  },
  async getById(id: string, scenarioId: string) {
    return await ApiClient.get<ChangeoverTransitionDto>(`/ChangeoverTransitions/${id}?scenarioId=${scenarioId}`);
  },
  async create(request: CreateChangeoverTransitionCommand) {
    return await ApiClient.put<string>(`/ChangeoverTransitions/`, request);
  },
  async update(request: UpdateChangeoverTransitionCommand) {
    const id = request.id;
    return await ApiClient.put(`/ChangeoverTransitions/${id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return await ApiClient.delete(`/ChangeoverTransitions/${id}?scenarioId=${scenarioId}`);
  }
  // async makeCopy(id: string, scenarioId: string) {
  //   return ApiClient.post(`/ChangeoverTransitions/${id}/makeCopy?scenarioId=${scenarioId}`);
  // }
};
