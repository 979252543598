import { ApiClient } from "@/services/api/common.api";
import {
  GanttCapacityTimeSectionsResponse,
  GanttDragAndDropContextResult,
  GanttResourceCapacitiesResponse,
  GanttResourceCapacityDto,
  GanttSettings as GanttSettingsDto,
  GetAllGanttResourcesQuery,
  GetAllGanttResourcesResponse,
  GetGanttCapacityTimeSectionsQuery,
  GetGanttSettingsQuery,
  GetGanttTaskRootConnectionsResult
} from "@masta/generated-model";

export default {
  async getGanttSettings(query: GetGanttSettingsQuery) {
    return ApiClient.post<GanttSettingsDto>("/gantt/settings", query);
  },
  async getAllResources(query: GetAllGanttResourcesQuery) {
    return ApiClient.post<GetAllGanttResourcesResponse>("/gantt/resources", query);
  },
  async getAllResourceCapacities(query: GetAllGanttResourcesQuery) {
    return ApiClient.post<GanttResourceCapacitiesResponse>("/gantt/capacities", query);
  },
  async getResourceCapacity(resourceCapacityId: string) {
    return ApiClient.get<GanttResourceCapacityDto>(`/gantt/capacities/${resourceCapacityId}`);
  },
  async getConnectors(id: string, scenarioId: string) {
    return ApiClient.get<GetGanttTaskRootConnectionsResult>(`/gantt/tasks/${id}/connections?scenarioId=${scenarioId}`);
  },
  async getCapacityTimeSections(query: GetGanttCapacityTimeSectionsQuery) {
    return ApiClient.post<GanttCapacityTimeSectionsResponse>(`/gantt/time-sections`, query);
  },
  async getDragAndDropContext(taskId: string, scenarioId: string) {
    return await ApiClient.get<GanttDragAndDropContextResult>(`/gantt/dnd-context/${taskId}?scenarioId=${scenarioId}`);
  }
};
