import ApiService from "@/services/api";
import { AppContextDto, GenericStatusColorSettings, ModelInstanceTabsSetting, StepStatusColorSettings, TaskStatusColorSettings } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  context: AppContextDto | null;
}

export const useSystemStore = defineStore("system", {
  state: (): State => ({
    context: null
  }),
  getters: {
    defaultSchedulingSettingName: (state): string => state.context?.defaultSchedulingSettingName ?? "",
    defaultSchedulingDragAndDropSettingName: (state): string => state.context?.defaultSchedulingDragAndDropSettingName ?? "",
    schedulingSettingNames: (state): string[] => state.context?.schedulingSettingNames ?? [],
    modelInstanceTabs: (state): ModelInstanceTabsSetting | null | undefined => state.context?.modelInstanceTabs,
    customerOrderStatuses: (state): GenericStatusColorSettings | undefined => state.context?.customerOrderStatusSettings,
    customerOrderLineStatuses: (state): GenericStatusColorSettings | undefined => state.context?.customerOrderLineStatusSettings,
    inboundDeliveryStatuses: (state): GenericStatusColorSettings | undefined => state.context?.inboundDeliveryStatusSettings,
    inboundDeliveryLineStatuses: (state): GenericStatusColorSettings | undefined => state.context?.inboundDeliveryLineStatusSettings,
    purchaseOrderStatuses: (state): GenericStatusColorSettings | undefined => state.context?.purchaseOrderStatusSettings,
    purchaseOrderLineStatuses: (state): GenericStatusColorSettings | undefined => state.context?.purchaseOrderLineStatusSettings,
    orderLineGroupStatuses: (state): GenericStatusColorSettings | undefined => state.context?.orderLineGroupStatusSettings,
    modelInstanceStatuses: (state): GenericStatusColorSettings | undefined => state.context?.modelInstanceStatusSettings,
    userNoticeStatuses: (state): GenericStatusColorSettings | undefined => state.context?.userNoticeStatusSettings,
    scenarioStatuses: (state): GenericStatusColorSettings | undefined => state.context?.scenarioStatusSettings,
    productTemplateStatuses: (state): GenericStatusColorSettings | undefined => state.context?.productTemplateStatusSettings,
    resourceStatuses: (state): GenericStatusColorSettings | undefined => state.context?.resourceStatusSettings,
    taskStatuses: (state): TaskStatusColorSettings | undefined => state.context?.taskStatusSettings,
    taskPlanningStatusColors: (state): GenericStatusColorSettings | undefined =>
      state.context?.taskStatusSettings?.taskPlanningStatusColors,
    taskSchedulingStatusColors: (state): GenericStatusColorSettings | undefined =>
      state.context?.taskStatusSettings?.taskSchedulingStatusColors,
    taskExecutionStatusColors: (state): GenericStatusColorSettings | undefined =>
      state.context?.taskStatusSettings?.taskExecutionStatusColors,
    taskTotalStatusColors: (state): GenericStatusColorSettings | undefined =>
      state.context?.taskStatusSettings?.taskTotalStatusColors,

    // Step-specific getters
    stepStatuses: (state): StepStatusColorSettings | undefined => state.context?.stepStatusColorSettings,
    stepSchedulingStatusColors: (state): GenericStatusColorSettings | undefined =>
      state.context?.stepStatusColorSettings?.stepSchedulingStatusColors,
    stepExecutionStatusColors: (state): GenericStatusColorSettings | undefined =>
      state.context?.stepStatusColorSettings?.stepExecutionStatusColors,
    stepTotalStatusColors: (state): GenericStatusColorSettings | undefined =>
      state.context?.stepStatusColorSettings?.stepTotalStatusColors

  },
  actions: {
    async fetchContext() {
      try {
        const { data } = await ApiService.system.getAppContext();
        this.context = data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
