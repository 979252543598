import { ApiClient } from "@/services/api/common.api";
import { CreateResourceNoteCommand, CreateTaskNoteCommand, GanttNoteDto, ResourceNoteDto, TaskNoteDto, UpdateNoteCommand } from "@masta/generated-model";

export default {
  async createResourceNote(note: CreateResourceNoteCommand) {
    return ApiClient.post<ResourceNoteDto>(`/notes/resource`, note);
  },
  async createTaskNote(note: CreateTaskNoteCommand) {
    return ApiClient.post<TaskNoteDto>(`/notes/task`, note);
  },
  async updateNote(note: UpdateNoteCommand) {
    return ApiClient.put(`/notes`, note);
  },
  async deleteNote(note: GanttNoteDto, scenarioId: string) {
    await ApiClient.delete(`notes/${note.id}/${note.revisionNumber}?scenarioId=${scenarioId}`);
  }
};
