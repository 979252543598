import { ApiClient } from "@/services/api/common.api";
import {
  AssignModelInstanceToResourceCommand,
  AvailabilityRulesResponseDto,
  DeassignModelInstanceFromResourceCommand,
  FindResourceResult,
  GetBomResult,
  GetResourceAssignedModelInstancesQuery,
  GetResourceInfoResult,
  RecomputeAllResourceCapacityScalesCommand,
  RecomputeResourceCapacityScaleCommand,
  ResourceSubType,
  ResourceType,
  UpdateResourceAvailabilityRuleAssignmentCommand,
  UpdateResourceModelInstanceAssignmentCommand
} from "@masta/generated-model";

function convertToSubTypeParams(resourceSubTypes: ResourceSubType[]) {
  if (!resourceSubTypes || resourceSubTypes.length <= 0) {
    return "";
  }
  return `&ResourceSubTypes=${resourceSubTypes.join(",")}`;
}

function convertToTypeParams(resourceTypes: ResourceType[]) {
  if (!resourceTypes) {
    return "";
  }
  return `&ResourceTypes=${resourceTypes.join(",")}`;
}

export default {
  async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
    return ApiClient.post(`/resources/updateModelAssignment`, request);
  },
  async assignModelInstance(request: AssignModelInstanceToResourceCommand) {
    return ApiClient.post(`/resources/assignModelInstance`, request);
  },
  async deassignModelInstance(request: DeassignModelInstanceFromResourceCommand) {
    return ApiClient.post(`/resources/deassignModelInstance`, request);
  },
  async getAvailabilityRules(id: string, scenarioId: string) {
    return ApiClient.get<AvailabilityRulesResponseDto>(`/resources/${id}/availabilityRules?scenarioId=${scenarioId}`);
  },
  async updateAvailabilityRuleAssignment(request: UpdateResourceAvailabilityRuleAssignmentCommand) {
    return ApiClient.post(`/resources/updateAvailabilityRuleAssignment`, request);
  },
  async findResources(q: string, scenarioId: string, resourceTypes: ResourceType[] = [], resourceSubTypes: ResourceSubType[] = [], fullTextSearch: boolean = false) {
    return ApiClient.get<FindResourceResult>(
      `/resources/query?q=${encodeURIComponent(q)}&scenarioId=${scenarioId}&fts=${fullTextSearch}${convertToTypeParams(resourceTypes)}${convertToSubTypeParams(resourceSubTypes)}`
    );
  },
  async getResourcesInfo(resourceIds: string[], scenarioId: string) {
    return ApiClient.get<GetResourceInfoResult>(
      `/resources/getResourcesInfo?resourceIds=${resourceIds.join(",")}&scenarioId=${scenarioId}`
    );
  },
  async getAssignedModelInstances(request: GetResourceAssignedModelInstancesQuery) {
    return ApiClient.post(`/resources/query/modelInstances`, request);
  },
  async getBom(id: string, scenarioId: string) {
    return ApiClient.get<GetBomResult>(`/resources/${id}/bom?scenarioId=${scenarioId}`);
  },
  async recomputeAllCapacityScales(request: Partial<RecomputeAllResourceCapacityScalesCommand>) {
    return ApiClient.post(`/resources/recomputeCapacityScale`, request);
  },
  async recomputeCapacityScale(id: string, scenarioId: string) {
    const request: RecomputeResourceCapacityScaleCommand = {
      resourceId: id,
      scenarioId: scenarioId
    };
    return ApiClient.post(`/resources/${id}/recomputeCapacityScale`, request);
  }
};
