import { createApp } from "vue";
import App from "@/App.vue";
import "@/aggrid-license";
import vuetify from "@/plugins/vuetify";

import { createRouter, createWebHistory } from "vue-router";
import TenantNotFound from "@/views/TenantNotFound.vue";
import { createPinia } from "pinia";
import { DayjsPlugin } from "@masta/shared";
import { i18n } from "@/i18n";

console.log("Tenant ID is not valid - tenantNotFound");

App.name = "MeMOM - Not Found";

export const app = createApp(App);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      component: TenantNotFound
    }
  ]
});

app
  .use(router)
  .use(i18n)
  .use(createPinia())
  .use(vuetify)
  .use(DayjsPlugin)
  .mount("#app");
