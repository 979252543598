import { ApiClient } from "@/services/api/common.api";
import { GetBusinessActionsPaginatedQuery, IAgGridPaginationResponse, UpdateUserNoticeCommand, UserNoticeDto } from "@masta/generated-model";

export default {
  async getById(id: string) {
    return ApiClient.get<UserNoticeDto>(`/userNotices/${id}`);
  },
  async getAll() {
    return ApiClient.get<IAgGridPaginationResponse<UserNoticeDto>>(`/userNotices`);
  },
  async getPaginated(request: GetBusinessActionsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<UserNoticeDto>>(`/userNotices`, request);
  },
  async getUnactionedCount() {
    return ApiClient.post(`/userNotices/unactionedCount`);
  },
  async markAcknowledged(id: string) {
    return ApiClient.post(`/userNotices/${id}/acknowledge`);
  },
  async markArchived(id: string) {
    return ApiClient.post(`/userNotices/${id}/archive`);
  },
  async update(userNotice: UpdateUserNoticeCommand) {
    return ApiClient.put(`/userNotices/${userNotice.id}`, userNotice);
  }
};
